const tips = [
  {
    num: 1,
    text: 'Complete your profile to improve Strength and apply for Opportunities',
  },
  {
    num: 2,
    text: 'Only your profile will be shared with the compnies when you apply',
  },
  {
    num: 3,
    text: 'Adding work experience, portfolio projects, education increases your chance of getting shortlisted by 2X',
  },
];

const index = () => {
  return (
    <div className='border-2 rounded-xl  px-4 py-6 bg-gradient-to-b from-white to-primary/20'>
      <div className='flex gap-2 mb-8'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1660283442/b2c/image_212_vufjoc.png'
          alt='logo'
        />
        <p className='font-bold text-base text-textColor-lightest'>
          Quick Tips
        </p>
      </div>
      {tips.map((tip: { num: number; text: string }, index: number) => {
        return (
          <div key={index} className='flex gap-x-2 mb-6'>
            <div>
              <p className='bg-primary rounded-full w-8 h-8 grid place-items-center text-white font-bold text-lg'>
                {tip.num}
              </p>
            </div>
            <p className='text-xs font-manrope tracking-wide'>{tip.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default index;
